// import Head from 'next/head';

// import { useAuth } from '@/lib/auth';

// export default function Home() {
//   const auth = useAuth();

//   const onSignIn = (event: any) => {
//     event.preventDefault();
//     auth.signinWithGithub();
//   };

//   const onSignOut = (event: any) => {
//     event.preventDefault();
//     auth.signout();
//   };
//   const displayButtons = () => {
//     if (auth.user) {
//       return (
//         <>
//           {auth?.user?.email}
//           <button onClick={onSignOut}>Sign out</button>
//         </>
//       );
//     } else {
//       return <button onClick={onSignIn}>Sign In</button>;
//     }
//   };
//   return (
//     <div>
//       <Head>
//         <title>Made In India Company</title>
//         <link rel="icon" href="/favicon.ico" />
//       </Head>

//       <main>
//         <h1>Made In India Company</h1>

//         <div>{displayButtons()}</div>
//       </main>

//       <footer>
//         <a
//           href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Powered by <img src="/vercel.svg" alt="Vercel Logo" />
//         </a>
//       </footer>
//     </div>
//   );
// }



const index = () => {
  return <div>hello</div>;
};

export default index;
